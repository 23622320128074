import {
  Children,
  useContext,
  createContext,
  useState,
  useEffect,
} from "react";
import Header from "./Header";
import Footer from "./Footer";
import SideNav from "./SideNav";
import QuickFacts from "./QuickFacts";
import { axiosInstance } from "./axiosFetchers";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

import SupportIcon from "../Assets/Icons/SupportIcon";
import FarmIcon from "../Assets/Icons/FarmIcon";
import FieldIcon from "../Assets/Icons/FieldIcon";
import AddFarmFieldIcon from "../Assets/Icons/AddFarmFieldIcon";
import DashBoardIcon from "../Assets/Icons/DashBoardIcon";
import FieldLibraryIcon from "../Assets/Icons/FieldLibraryIcon";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import { useAuth } from "../Components/Auth/useAuth";

import { GUI_CONTEXT } from "../App";

export default function Page({
  title,
  children,
  dashboardData = false,
  showQuickFacts = false,
  headerBorderColor = "border-[#34a853]",
  padding = "p-[32px]",
}) {
  const GUI = useContext(GUI_CONTEXT);
  const { user } = useAuth();
  // const [sideNavState, setSideNavState] = useState({
  //   menu: [],
  //   horizontallyCollapsed: false,
  // });

  function handleSideNavCollapse() {
    GUI.setter((prev) => {
      return { ...prev, horizontallyCollapsed: !prev.horizontallyCollapsed };
    });
  }

  useEffect(() => {
    async function startupSideNav() {
      const FarmsAndFields = [];

      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/farms`,
        {
          headers: {
            token: "Bearer " + user.token,
            provider: user.provider,
            "Content-Type": "application/json",
          },
        }
      );
      for (const FARM of response.data.data) {
        var farmObj = {};

        farmObj.icon = <FarmIcon />;
        farmObj.title = FARM.name;

        farmObj.to = "/farm/" + FARM._id.$oid;

        const farm_fields_response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}/farms/${FARM._id.$oid}/fields`,
          {
            headers: {
              token: "Bearer " + user.token,
              provider: user.provider,
              "Content-Type": "application/json",
            },
          }
        );
        farmObj.items = farm_fields_response.data.map((field) => {
          return {
            icon: <FieldIcon />,
            title: field.name,
            to: farmObj.to + "/field/" + field._id.$oid,
          };
        });
        farmObj.items.push({
          title: "Add Field",
          to: `/farm/${FARM._id.$oid}/addfield`,
          icon: <AddFarmFieldIcon />,
        });
        FarmsAndFields.push(farmObj);
      }

      FarmsAndFields.push({
        title: "Add Farm",
        to: "/addfarm",
        icon: <AddFarmFieldIcon />,
      });

      GUI.setter((prev) => {
        return {
          ...prev,
          fetched: true,
          menu: [
            {
              icon: <DashBoardIcon />,
              title: "Dashboard",
              items: [],
              to: "/dashboard",
              borderColor: "#00ADEE",
            },
            {
              icon: <FieldLibraryIcon />,
              title: "Field Library",
              items: FarmsAndFields,
              borderColor: "#34a853",
            },
            {
              icon: <SupportIcon />,
              title: "Support",
              onClick: console.log,
              borderColor: "#FFF",
            },
            {
              icon: "collapse",
              title: "Collapse Panel",
              onClick: (e) => {
                handleSideNavCollapse();
              },
            },
          ],
        };
      });
    }
    if (!GUI.state.fetched) startupSideNav();
  }, [GUI.state]);

  return (
    <div className="w-screen h-screen flex flex-col ">
      <Header />

      <div className="flex overflow-auto grow">
        <SideNav />
        <div id="scrollableDiv" className="flex flex-col  grow overflow-auto">
          {/* PAGE HEADER */}
          <header
            className={` border-b-[3px] min-h-[101px] ${headerBorderColor} bg-[#eeeeee] text-[#666666] text-[34px] font-light py-[19px] px-[29px] truncate`}
          >
            {title}
          </header>
          {/* QuickFacts  */}
          {showQuickFacts && (
            <QuickFacts
              dashboardData={dashboardData}
              sectionColor={headerBorderColor.slice(8, 15)}
            />
          )}

          {/* PAGE BODY */}
          <main className={`flex flex-col h-full w-full ${padding} `}>
            {Children.map(children, (child) => (
              // <child />
              <div className="flex h-full w-full">{child}</div>
            ))}
          </main>
        </div>
      </div>

      <Footer />
    </div>
  );
}
