import { Divider, TextField } from "@mui/material";
import SelectMachineField from "./FieldOperationsSubForms/SelectMachineField";

import FormSelectField from "../FormInputElements/FormSelectField";
import FormTextField from "../FormInputElements/FormTextField";

import {
  CropYearCropDryingType,
  CropYearCropDryingFuel,
} from "../../Assets/DataTypes";

import { CropYearContext } from "../../App";
import { FarmContext } from "../../App";
import { useContext, useEffect, useState } from "react";

export default function HarvestForm({ LowerPanel, panelControls }) {
  const cropyearContext = useContext(CropYearContext);
  const farmContext = useContext(FarmContext);

  const [errorFields, setErrorFields] = useState({
    swather_machineObj: false,
    swather_hoursUsed: false,
    combine_machineObj: false,
    combine_hoursUsed: false,
    combine_avgSpeed: false,
  });

  function handleMachineChange(machineId, machineType) {
    let cpy = { ...cropyearContext.state };

    cpy.harvest[machineType].machineId = machineId;

    console.log(machineId);
    cropyearContext.setter(cpy);
  }

  function handleHoursChange(hoursUsed, machineType) {
    let cpy = { ...cropyearContext.state };

    cpy.harvest[machineType].hoursUsed = hoursUsed;

    cropyearContext.setter(cpy);
  }
  function handleSpeedChange(avgSpeed) {
    let cpy = { ...cropyearContext.state };

    cpy.harvest["combine"].avgSpeed = avgSpeed;

    cropyearContext.setter(cpy);
  }

  function handleCropDrying(target, key) {
    const newValue = {};
    newValue[key] = target;

    // create a copy of crop obj
    const updatedHarvest = { ...cropyearContext.state.harvest, ...newValue };

    cropyearContext.setter({
      ...cropyearContext.state,
      harvest: updatedHarvest,
    });
  }

  function handleMouistureChange(target, key) {
    const newValue = {};
    newValue[key] = target;

    const updatedMoisture = {
      ...cropyearContext.state.harvest.moisture,
      ...newValue,
    };

    const updatedHarvest = {
      ...cropyearContext.state.harvest,
      moisture: updatedMoisture,
    };
    cropyearContext.setter({
      ...cropyearContext.state,
      harvest: updatedHarvest,
    });
  }

  function isInputValid() {
    return true;
    console.log(cropyearContext.state.harvest.swather);

    errorFields.swather_machineObj =
      cropyearContext.state.harvest.swather.machineId === "";
    errorFields.swather_hoursUsed =
      cropyearContext.state.harvest.swather.hoursUsed === 0;
    errorFields.combine_machineObj =
      cropyearContext.state.harvest.combine.machineId === "";
    errorFields.combine_hoursUsed =
      cropyearContext.state.harvest.combine.hoursUsed === 0;
    errorFields.combine_avgSpeed =
      cropyearContext.state.harvest.combine.avgSpeed === "";

    if (
      errorFields.swather_machineObj ||
      errorFields.swather_hoursUsed ||
      errorFields.combine_machineObj ||
      errorFields.combine_hoursUsed ||
      errorFields.combine_avgSpeed
    ) {
      setErrorFields({
        swather_machineObj: errorFields.swather_machineObj,
        swather_hoursUsed: errorFields.swather_hoursUsed,
        combine_machineObj: errorFields.combine_machineObj,
        combine_hoursUsed: errorFields.combine_hoursUsed,
        combine_avgSpeed: errorFields.combine_avgSpeed,
      });
      console.log(errorFields);
      return false; // return false because input is invalid
    } else {
      return true;
    }
  }
  useEffect(() => {
    const element = document.getElementById("scrollableDiv");
    element.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className=" w-full h-full ">
      <h3 className="text-[rgb(102,102,102)] text-[30px]">Harvest</h3>
      <Divider sx={{ marginBottom: 3 }} />

      <section>
        <h4 className="text-[rgb(102,102,102)] font-extralight text-[36px] mb-4">
          Equipment
        </h4>

        <div className="flex jusitify-start">
          <div className="w-[450px] mr-[25px]">
            <SelectMachineField
              errorFound={{
                machineObj: errorFields.swather_machineObj,
                machineHours: errorFields.swather_hoursUsed,
              }}
              fieldLabel={"Select Swather"}
              machineType={"SWATHER"}
              handleMachineChange={(machineId) =>
                handleMachineChange(machineId, "swather")
              }
              handleHoursChange={(hoursUsed) =>
                handleHoursChange(hoursUsed, "swather")
              }
              fieldState={cropyearContext.state.harvest.swather}
            />
          </div>
        </div>

        <div className="flex jusitify-start mb-6">
          <div className="w-[450px] mr-[25px]">
            <SelectMachineField
              errorFound={{
                machineObj: errorFields.combine_machineObj,
                machineHours: errorFields.combine_hoursUsed,
              }}
              fieldLabel={"Select Combine"}
              machineType={"COMBINE"}
              handleMachineChange={(machineId) =>
                handleMachineChange(machineId, "combine")
              }
              handleHoursChange={(hoursUsed) =>
                handleHoursChange(hoursUsed, "combine")
              }
              fieldState={cropyearContext.state.harvest.combine}
            />
          </div>
          <TextField
            error={errorFields.combine_avgSpeed}
            type="number"
            label="Avg Speed Miles/Hr"
            sx={{ width: 200, marginTop: "16px" }}
            InputProps={{ inputProps: { min: 0 } }}
            onChange={(e) => handleSpeedChange(e.target.value)}
            value={cropyearContext.state.harvest.combine.avgSpeed}
          />
        </div>
      </section>
      {/* <section>
        <h4 className="text-[rgb(102,102,102)] font-extralight text-[36px] mb-4">
          Crop Drying
        </h4>
        <FormSelectField
          valuesArray={CropYearCropDryingType}
          fieldLabel={"Crop drying -- type"}
          modalTitle={"Crop Drying -- Type"}
          modalDescription={"Some Really helpful description"}
          onChange={(e) => handleCropDrying(e.target.value, "cropDryingType")}
          fieldState={cropyearContext.state.harvest.cropDryingType}
        />
        <FormSelectField
          valuesArray={CropYearCropDryingFuel}
          fieldLabel={"Crop drying -- fuel"}
          modalTitle={"Crop Drying -- Fuel"}
          modalDescription={"Some Really helpful description"}
          onChange={(e) => handleCropDrying(e.target.value, "cropDryingFuel")}
          fieldState={cropyearContext.state.harvest.cropDryingFuel}
        />
      </section>
      <section className="pb-8">
        <h4 className="text-[rgb(102,102,102)] font-extralight text-[36px] mb-4">
          Moisture Content
        </h4>
        <FormTextField
          fieldLabel={"% Before Drying"}
          isNumber={true}
          onChange={(t) => handleMouistureChange(t, "beforeDrying")}
          modalTitle={"Moisture Content Before Drying"}
          modalDescription={
            "Please Enter the Percentage amount of Moisture in the soil before drying."
          }
          fieldState={cropyearContext.state.harvest.moisture.beforeDrying}
        />
        <FormTextField
          fieldLabel={"% After Drying"}
          isNumber={true}
          onChange={(t) => handleMouistureChange(t, "afterDrying")}
          modalTitle={"Moisture Content After Drying"}
          modalDescription={
            "Please Enter the Percentage amount of Moisture in the soil after drying."
          }
          fieldState={cropyearContext.state.harvest.moisture.afterDrying}
        />
      </section> */}
      <LowerPanel
        activeStep={panelControls.activeStep}
        handleBack={panelControls.handleBack}
        handleNext={panelControls.handleNext}
        steps={panelControls.steps}
        handleComplete={panelControls.handleComplete}
        completedSteps={panelControls.completedSteps}
        totalSteps={panelControls.totalSteps}
        isInputValid={isInputValid}
      />
    </div>
  );
}
