import { CropYearContext, FarmContext } from "../../../App";
import { useContext, useEffect, useState } from "react";
import NumberQuestion from "../../FormInputElements/NumberQuestion";
import SelectMachineField from "./SelectMachineField";

export default function PesticidesForm({}) {
  const cropyearContext = useContext(CropYearContext);
  const farmContext = useContext(FarmContext);

  const [numberOfPesticide, setNumberOfPesticide] = useState(
    cropyearContext.state.fieldOperations.pesticidesApplications.length
  );

  useEffect(() => {
    // const element = document.getElementById("scrollableDiv");
    // element.scrollBy({ top: 100, left: 0, behavior: "smooth" });
  }, [cropyearContext.state]);

  function handleNumberPesticideChange(amount) {
    console.log(amount, "mami");
    var cropyearCopy = { ...cropyearContext.state };

    if (amount < numberOfPesticide) {
      cropyearCopy.fieldOperations.pesticidesApplications.pop();
    } else if (cropyearCopy.fieldOperations.pesticidesApplications.length < 6) {
      cropyearCopy.fieldOperations.pesticidesApplications.push({
        machineId: "",
        hoursUsed: 0,
      });
    }
    setNumberOfPesticide(amount);
    cropyearContext.setter(cropyearCopy);
  }

  function handleMachineChange(machineId, index) {
    let cpy = { ...cropyearContext.state };

    cpy.fieldOperations.pesticidesApplications[index].machineId = machineId;

    console.log(machineId);
    cropyearContext.setter(cpy);
  }

  function handleHoursChange(hoursUsed, index) {
    let cpy = { ...cropyearContext.state };

    cpy.fieldOperations.pesticidesApplications[index].hoursUsed = hoursUsed;

    cropyearContext.setter(cpy);
  }

  return (
    <section className="pb-5">
      <h4 className="text-[rgb(102,102,102)] font-extralight text-[36px]">
        Pesticide
      </h4>

      <NumberQuestion
        min={0}
        max={5}
        fieldValue={numberOfPesticide}
        questionText={
          "How many pesticide operations this field had this crop year?"
        }
        modalTitle={"Number of Pesticide Operations"}
        modalDescription={
          "Please provide the number of pesticide operations you had in this year for this specific field."
        }
        onChange={handleNumberPesticideChange}
      />

      <ul className="transition-all duration-500">
        {cropyearContext.state.fieldOperations.pesticidesApplications.map(
          (pesticide, index) => {
            if (index > numberOfPesticide - 1) return;
            return (
              <SelectMachineField
                key={index}
                fieldLabel={"Sprayer Used in Fumigation #" + (index + 1)}
                machineType={"SPRAYER"}
                index={index}
                fieldState={pesticide}
                handleHoursChange={(hoursUsed) =>
                  handleHoursChange(hoursUsed, index)
                }
                handleMachineChange={(machineId) =>
                  handleMachineChange(machineId, index)
                }
              />
            );
          }
        )}
      </ul>
    </section>
  );
}
