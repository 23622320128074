import React, { useEffect, useState, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  List,
  ListItem,
} from "@mui/material";
import { CropYearContext } from "../../../App";

import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { axiosInstance } from "../../axiosFetchers";
import { useAuth } from "../../Auth/useAuth";
import { useParams } from "react-router-dom";

export default function AssessmentResultsTable() {
  const [openRows, setOpenRows] = useState({});
  const { user } = useAuth();
  const { cropyearId } = useParams();
  const cropYearContext = useContext(CropYearContext);

  const [rows, setRows] = useState([]);
  const [isValidCrop4R, setIsValidCrop4R] = useState(false);

  useEffect(() => {
    let tmp =
      cropYearContext.state.crop.cropThisYear === "SPRING_WHEAT" ||
      cropYearContext.state.crop.cropThisYear === "CANOLA";

    setIsValidCrop4R(tmp);
  }, [cropYearContext.state]);

  useEffect(() => {
    async function startupCall() {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/assessments/${cropyearId}`,
        {
          headers: {
            token: "Bearer " + user.token,
            provider: user.provider,
          },
        }
      );
      if (response.data) {
        setRows([
          {
            parameter: "Rate",
            nitrogen: response.data.assessmentResults.rateNitrogen.result,
            phosphorous: response.data.assessmentResults.ratePhos.result,
            errorMessages: [],
          },
          {
            parameter: "Timing",
            nitrogen: response.data.assessmentResults.timeNitrogen.result,
            phosphorous: response.data.assessmentResults.timePhos.result,
            errorMessages: [],
          },
          {
            parameter: "Placement",
            nitrogen: response.data.assessmentResults.placementNitrogen.result,
            phosphorous: response.data.assessmentResults.placementPhos.result,
            errorMessages: [],
          },
        ]);
      }
    }
    startupCall();
  }, []);

  const toggleRow = (index) => {
    setOpenRows((prevOpenRows) => ({
      ...prevOpenRows,
      [index]: !prevOpenRows[index],
    }));
  };

  if (isValidCrop4R == false) return null;
  else
    return (
      <div className="w-full max-w-[600px]">
        <TableContainer component={Paper}>
          {/* <div className="bg-[#34a853]">  */}
          <div>
            <h2 className="text-[24px] ml-3 text-[rgba(0,0,0,0.87)] mb-4">
              4R Nutrient Stewardship Project
            </h2>
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Nitrogen</TableCell>
                <TableCell>Phosphorous</TableCell>
                <TableCell size="small" />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <React.Fragment key={index}>
                  <TableRow onClick={() => toggleRow(index)}>
                    <TableCell>{row.parameter}</TableCell>
                    <TableCell>
                      {row.nitrogen ? (
                        <CheckCircleIcon sx={{ color: "green" }} />
                      ) : (
                        <CancelIcon sx={{ color: "red" }} />
                      )}
                    </TableCell>
                    <TableCell>
                      {row.phosphorous ? (
                        <CheckCircleIcon sx={{ color: "green" }} />
                      ) : (
                        <CancelIcon sx={{ color: "red" }} />
                      )}
                    </TableCell>
                    <TableCell size="small">
                      <IconButton aria-label="expand row" size="small">
                        {openRows[index] ? (
                          <KeyboardArrowUp />
                        ) : (
                          <KeyboardArrowDown />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      colSpan={4}
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                    >
                      <Collapse
                        in={openRows[index]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List>
                          {row.errorMessages.length > 0 ? (
                            row.errorMessages.map((msg, msgIndex) => (
                              <ListItem key={msgIndex}>{msg}</ListItem>
                            ))
                          ) : (
                            <ListItem>No error messages</ListItem>
                          )}
                        </List>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
}
