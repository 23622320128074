import { useState, useEffect } from "react";
import { useAuth } from "../Components/Auth/useAuth";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../Components/Header";
import TextField from "@mui/material/TextField";

import "@fontsource/roboto-slab";
import { axiosInstance } from "../Components/axiosFetchers";
import MainButton from "../Components/Buttons/MainButton";
import qs from "qs";
import Footer from "../Components/Footer";
import GrayButton from "../Components/Buttons/GrayButton";

export const ResetPasswordPage = () => {
  const navigate = useNavigate();

  const [userEmail, setUserEmail] = useState("");
  const [newPasword, setNewPassword] = useState("");
  const [id, setId] = useState("");

  document.title = "Password Reset Page - Field To Market Canada";

  async function handleEmailResetRequest() {
    try {
      const response = await axiosInstance.patch(
        process.env.REACT_APP_API_URL + `/password_reset/${id}`,
        { email: userEmail, new_password: newPasword },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      alert("Password Updated Successfully");
      navigate("/login");
    } catch {
      alert(
        "Your request could not be processed, please contact support support@fieldtomarket.ca"
      );
      navigate("/login");
    }
  }

  useEffect(() => {
    const url = document.location.toString();

    if (url.includes("id")) {
      let tmp = document.location.toString().split("id=")[1];
      setId(tmp);
    }
  }, []);

  return (
    <div className="w-screen relative text-lg  text-[#666] h-screen overflow-scroll">
      <div className="w-full mb-20">
        <Header />
      </div>

      <div className="w-full overflow-scroll">
        <div className="max-w-[706px] mx-auto px-4 pb-20">
          <div className="p-5 bg-[#F5F5F5]">
            <h2
              style={{
                fontFamily: "Roboto Slab",
                fontSize: "22px",
                fontWeight: "700",
                marginBottom: "16px",
                color: "black",
                marginBottom: "16px",
              }}
            >
              Reset Password Form
            </h2>

            <div className="w-full flex mb-6">
              <div className="w-full flex flex-col">
                <div className="flex flex-col mb-4">
                  <TextField
                    style={{ marginRight: "16px", marginBottom: "16px" }}
                    label="Email"
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                  />
                  <TextField
                    style={{ marginRight: "16px", marginBottom: "16px" }}
                    label="New Password"
                    type="password"
                    value={newPasword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <MainButton
                    text={"Reset Password"}
                    onClick={handleEmailResetRequest}
                    grow={true}
                  />
                </div>

                <MainButton
                  text={"Register"}
                  secondary={true}
                  onClick={() => navigate("/registration")}
                  grow={true}
                />
              </div>
            </div>

            <p>
              Need Help? Visit the{" "}
              <span className="text-[#FF7D32] underline">Support Portal</span>{" "}
              or contact us at{" "}
              <span className="text-[#FF7D32] underline">
                support@fieldtomarket.ca
              </span>
              . Password reset is available from the login page.
            </p>
          </div>
        </div>
      </div>
      <div className="w-full fixed bottom-0">
        <Footer />
      </div>
    </div>
  );
};
