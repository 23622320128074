import { CropYearContext, FarmContext } from "../../../App";
import { useContext } from "react";
import { useState, useEffect } from "react";
import NumberQuestion from "../../FormInputElements/NumberQuestion";
import SelectMachineField from "./SelectMachineField";

export default function CultivationsForm({}) {
  const farmContext = useContext(FarmContext);
  const cropyearContext = useContext(CropYearContext);
  const [numberOfCultivation, setNumberOfCultivation] = useState(
    cropyearContext.state.fieldOperations.cultivations.length
  );

  useEffect(() => {}, [numberOfCultivation, cropyearContext.state]);

  function handleNumberCultivationChange(amount) {
    var cropyearCopy = { ...cropyearContext.state };

    if (amount < numberOfCultivation) {
      cropyearCopy.fieldOperations.cultivations.pop();
    } else if (cropyearCopy.fieldOperations.cultivations.length < 4) {
      cropyearCopy.fieldOperations.cultivations.push({
        machineId: "",
        hoursUsed: 0,
      });
    }
    setNumberOfCultivation(amount);
    cropyearContext.setter(cropyearCopy);
  }

  function handleMachineChange(machineId, index) {
    let cpy = { ...cropyearContext.state };

    cpy.fieldOperations.cultivations[index].machineId = machineId;

    console.log(machineId);
    cropyearContext.setter(cpy);
  }

  function handleHoursChange(hoursUsed, index) {
    let cpy = { ...cropyearContext.state };

    cpy.fieldOperations.cultivations[index].hoursUsed = hoursUsed;

    cropyearContext.setter(cpy);
  }

  return (
    <section>
      <h4 className="text-[rgb(102,102,102)] font-extralight text-[36px]">
        Cultivation
      </h4>
      <NumberQuestion
        min={0}
        max={4}
        fieldValue={numberOfCultivation}
        questionText={
          "How many cultivation operations this field had this crop year?"
        }
        modalTitle={"Number of Cultivation Operations"}
        modalDescription={
          "Please provide the number of cultivation operations you had in this year for this specific field."
        }
        onChange={handleNumberCultivationChange}
      />

      <ul className="transition-all duration-500">
        {cropyearContext.state.fieldOperations.cultivations.map(
          (cultivation, index) => {
            // if (index > numberOfCultivation - 1) return;

            return (
              <SelectMachineField
                key={index}
                fieldLabel={"Tractor Used in Cultivation #" + (index + 1)}
                machineType={"TRACTOR"}
                index={index}
                fieldState={cultivation}
                handleHoursChange={(hoursUsed) =>
                  handleHoursChange(hoursUsed, index)
                }
                handleMachineChange={(machineId) =>
                  handleMachineChange(machineId, index)
                }
              />
            );
          }
        )}
      </ul>
    </section>
  );
}
