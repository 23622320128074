import React, { useState, useContext, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import FertilizerMixModal from "./FertilizerMixModal";
import {
  TableRow,
  TableCell,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";
import { FourRAssessmentContext } from "../../../App";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { axiosInstance } from "../../axiosFetchers";
import { useAuth } from "../../../Components/Auth/useAuth";
// Array for fertilizer mix options
// const fertilizerMixesArray = [
//   {
//     _id: { $oid: "thecooliferd" },
//     name: "Powermix",
//     ingredients: [
//       { ingredientID: "xyz", amountInPounds: 40, proportion: 0.8 },
//       { ingredientID: "zzz", amountInPounds: 10, proportion: 0.2 },
//     ],
//     proxy_npks: "40.4-9.2-0.0-0.0",
//     "totals lb/ac": { N: 20.2, P: 4.6, K: 0.0, S: 0.0 },
//     ownerId: "email",
//   },
// ];

export default function FertilizerApplication4R({ data, index, error }) {
  const [dateState, setDateState] = useState("");
  const [isFertMixOpen, setFertMixOpen] = React.useState(false);
  const [updatedMixes, setUpdatedMixes] = useState(false);
  const [fertilizerMixesArray, setFertilizerMixesArray] = useState([]);
  const { user } = useAuth();

  const handleOpenFertMixModal = (e) => {
    e.stopPropagation();
    setFertMixOpen(true);
  };
  const handleCloseFertMixModal = () => {
    setFertMixOpen(false);
  };
  const handleSaveFertilizerMix = async (FertMixData) => {
    console.log(FertMixData);
    const response = await axiosInstance.post(
      process.env.REACT_APP_API_URL + `/assessments/fertilizer_mixes`,
      {
        ...FertMixData,
      },
      {
        headers: {
          token: "Bearer " + user.token,
          provider: user.provider,
          "Content-Type": "application/json",
        },
      }
    );

    handleUpdateFertilizerApplication(
      "fertilizerMixId",
      response.data.obj._id.$oid
    );
    setFertMixOpen(false);

    setUpdatedMixes((prev) => !prev);
  };

  const fourRAssessmentContext = useContext(FourRAssessmentContext);

  useEffect(() => {
    async function fetchMixes() {
      const response = await axiosInstance.get(
        process.env.REACT_APP_API_URL + `/assessments/fertilizer_mixes`,
        {
          headers: {
            token: "Bearer " + user.token,
            provider: user.provider,
            "Content-Type": "application/json",
          },
        }
      );

      setFertilizerMixesArray(response.data);
    }

    fetchMixes();
  }, [fourRAssessmentContext.state, updatedMixes]);

  function handleUpdateFertilizerApplication(property, value) {
    const prev = { ...fourRAssessmentContext.state };
    prev.fertilizerApplications[index][property] = value;
    fourRAssessmentContext.setter(prev);
  }
  function handleFertApplicationDelete() {
    const prev = { ...fourRAssessmentContext.state };
    prev.fertilizerApplications.splice(index, 1);
    fourRAssessmentContext.setter(prev);
  }

  function handleMoveUpDown(direction) {
    const prev = { ...fourRAssessmentContext.state };
    if (prev.fertilizerApplications.length <= 1) return;

    if (direction < 0) {
      // means move up
      if (index === 0) return;
    } else {
      // means move down
      if (index === prev.fertilizerApplications.length - 1) return;
    }

    let tmp = prev.fertilizerApplications[index];
    prev.fertilizerApplications[index] =
      prev.fertilizerApplications[index + direction];
    prev.fertilizerApplications[index + direction] = tmp;
    fourRAssessmentContext.setter(prev);
  }

  const handleDateChange = (newDate) => {
    if (newDate) {
      const formattedDate = dayjs(newDate).format("DD-MM-YYYY");

      setDateState(formattedDate);

      const prev = { ...fourRAssessmentContext.state };
      prev.fertilizerApplications[index]["applicationDate"] = formattedDate;
      fourRAssessmentContext.setter(prev);
    }
  };

  const methods = ["Banded", "Broadcast"];
  const timings = [
    "Fall Previous Year",
    "Before Planting",
    "Planting",
    "After Planting / in-crop",
  ];
  const depths = [0, 0.5, 1, 2, 2.5, 3, 3.5];
  const soilStates = [
    "Frozen / Snow Covered",
    "Moist",
    "Dry",
    "Arid",
    "Ideal (At Field Capacity)",
  ];

  return (
    <TableRow>
      {/* Index */}
      <TableCell>{index + 1}</TableCell>

      {/* Date Picker */}
      <TableCell>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Date"
            views={["year", "month", "day"]}
            value={
              data.applicationDate
                ? dayjs(data.applicationDate, "DD-MM-YYYY")
                : null
            }
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} fullWidth />}
            sx={
              error.applicationDate
                ? {
                    "& .MuiOutlinedInput-root": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#d32f2f",
                      },
                    },
                  }
                : {}
            }
          />
        </LocalizationProvider>
      </TableCell>

      {/* Method Select */}
      <TableCell>
        <FormControl fullWidth>
          <InputLabel>Method</InputLabel>
          <Select
            value={data.applicationMethod}
            onChange={(e) =>
              handleUpdateFertilizerApplication(
                "applicationMethod",
                e.target.value
              )
            }
            error={error.applicationMethod}
          >
            {methods.map((method) => (
              <MenuItem key={method} value={method}>
                {method}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>

      {/* Timing Select */}
      <TableCell>
        <FormControl fullWidth>
          <InputLabel>Timing</InputLabel>
          <Select
            value={data.fertilizerTiming}
            onChange={(e) =>
              handleUpdateFertilizerApplication(
                "fertilizerTiming",
                e.target.value
              )
            }
            error={error.fertilizerTiming}
          >
            {timings.map((timing) => (
              <MenuItem key={timing} value={timing}>
                {timing}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>

      {/* Depth Select */}
      <TableCell>
        <FormControl fullWidth>
          <InputLabel>Depth</InputLabel>
          <Select
            value={data.incorporationDepth}
            onChange={(e) =>
              handleUpdateFertilizerApplication(
                "incorporationDepth",
                e.target.value
              )
            }
          >
            {depths.map((depth) => (
              <MenuItem key={depth} value={depth}>
                {depth}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>

      {/* Soil State Select */}
      <TableCell>
        <FormControl fullWidth>
          <InputLabel>Soil State</InputLabel>
          <Select
            value={data.soilState}
            onChange={(e) =>
              handleUpdateFertilizerApplication("soilState", e.target.value)
            }
            error={error.soilState}
          >
            {soilStates.map((state) => (
              <MenuItem key={state} value={state}>
                {state}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>

      {/* Fertilizer Mix Select */}
      <TableCell>
        <FormControl fullWidth>
          <InputLabel>Fertilizer Mix</InputLabel>
          <Select
            value={data.fertilizerMixId}
            onChange={(e) =>
              handleUpdateFertilizerApplication(
                "fertilizerMixId",
                e.target.value
              )
            }
            error={error.fertilizerMixId}
          >
            {fertilizerMixesArray.map((fert) => (
              <MenuItem key={fert._id.$oid} value={fert._id.$oid}>
                {fert.name + " " + fert.proxy_npks}
              </MenuItem>
            ))}
            <MenuItem value="">
              <button onClick={handleOpenFertMixModal}>
                {"Add Fertilizer Mix"}
              </button>
            </MenuItem>
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <IconButton onClick={handleFertApplicationDelete}>
          <ClearIcon />
        </IconButton>
        <IconButton disabled={index === 0} onClick={() => handleMoveUpDown(-1)}>
          <ArrowUpwardIcon />
        </IconButton>
        <IconButton
          disabled={
            index ===
            fourRAssessmentContext.state.fertilizerApplications.length - 1
          }
          onClick={() => handleMoveUpDown(1)}
        >
          <ArrowDownwardIcon />
        </IconButton>
      </TableCell>
      <FertilizerMixModal
        open={isFertMixOpen}
        handleClose={handleCloseFertMixModal}
        handleSave={handleSaveFertilizerMix}
      />
    </TableRow>
  );
}
