import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import HelpIconButton from "../../Buttons/Iconbuttons/HelpIconButton";
import NewMachineModal from "./NewMachineModal";
import { FarmContext } from "../../../App";
import { CropYearContext } from "../../../App";
import { useState, useContext, useEffect } from "react";
import { axiosInstance } from "../../axiosFetchers";
import { useAuth } from "../../../Components/Auth/useAuth";

export default function SelectMachineField({
  fieldLabel,
  machineType,
  fieldState,
  handleMachineChange,
  handleHoursChange,
  errorFound = { machineObj: false, hoursUsed: false },
}) {
  const [machinesArray, setMachinesArray] = useState([]);
  const [isTractorModalOpen, setIsTractorModalOpen] = useState(false);
  const farmContext = useContext(FarmContext);
  const cropyearContext = useContext(CropYearContext);

  const { user } = useAuth();

  async function handleAddNewMachine(newMachine) {
    let farmId = farmContext.state._id.$oid;

    const response = await axiosInstance.post(
      process.env.REACT_APP_API_URL + `/machines`,
      {
        ...newMachine,
        farmId: farmId,
      },
      {
        headers: {
          token: "Bearer " + user.token,
          provider: user.provider,
          "Content-Type": "application/json",
        },
      }
    );
    farmContext.setter({
      ...farmContext.state,
      machines: [...farmContext.state.machines, response.data.machineObj],
    });

    handleMachineChange(response.data.machineObj._id.$oid);
  }
  useEffect(() => {
    setMachinesArray(
      farmContext.state.machines.filter((m) => m.type === machineType)
    );
  }, [farmContext, machineType]);

  return (
    <div className="min-w-[500px] flex mt-4">
      <TextField
        id="outlined-select-currency"
        select
        error={errorFound.machineObj}
        sx={{ width: "100%", maxWidth: "300px", marginRight: 4 }}
        value={fieldState.machineId}
        label={fieldLabel || "Select Equipment"}
        onChange={(e) => handleMachineChange(e.target.value)}
      >
        <MenuItem key={"None"} value={""}>
          <p>None</p>
        </MenuItem>
        {machinesArray.map((tractorOption) => {
          return (
            <MenuItem
              key={tractorOption.name}
              value={tractorOption["_id"]["$oid"]}
            >
              <p>{tractorOption.name}</p>
            </MenuItem>
          );
        })}
        <MenuItem
          key={"addTractor"}
          onClick={() => setIsTractorModalOpen(true)}
        >
          <p>{`Add a New ${
            machineType[0] + machineType.slice(1).toLowerCase()
          }`}</p>
        </MenuItem>
      </TextField>
      <TextField
        sx={{ color: "#666666", maxWidth: 130 }}
        label={"Hours Used"}
        InputProps={{ inputProps: { min: 0 } }}
        type="number"
        error={errorFound.machineHours}
        onChange={(e) => handleHoursChange(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={fieldState.hoursUsed}
      />

      {isTractorModalOpen && (
        <NewMachineModal
          open={isTractorModalOpen}
          handleClose={setIsTractorModalOpen}
          machineLabel={fieldLabel}
          machineType={machineType}
          handleAddNewMachine={handleAddNewMachine}
        />
      )}
    </div>
  );
}
