import Page from "../Components/Page";
import FormTextField from "../Components/FormInputElements/FormTextField";
import FormSelectField from "../Components/FormInputElements/FormSelectField";
import MainButton from "../Components/Buttons/MainButton";
import { useNavigate } from "react-router-dom";
import { CanadianProvinces } from "../Assets/DataTypes";
import { useEffect, useState } from "react";

import { useContext } from "react";
import { FarmContext } from "../App";
import { FieldContext } from "../App";
import { useAuth } from "../Components/Auth/useAuth";
import WarningMessageModal from "../Components/WarningMessageModal";
import { axiosInstance } from "../Components/axiosFetchers";

import { GUI_CONTEXT } from "../App";

var errorFields = {
  name: false,
  province: false,
};

export default function AddFarmPage() {
  const { user } = useAuth();
  const [ErrorFound, setErrorFound] = useState(false);
  const GUI = useContext(GUI_CONTEXT);
  const farmContext = useContext(FarmContext);
  const fieldContext = useContext(FieldContext);
  const [existingFarmNames, setExistingFarmNames] = useState([]);
  const [existingPartners, setExistingPartners] = useState([
    { id: "11", name: "Domenca" },
    { id: "33", name: "z-space" },
  ]);

  const [warningOpen, setWarningOpen] = useState(false);
  document.title = "Add Farm Page - Field To Market Canada";

  const navigate = useNavigate();

  function handleStateChange(target, key) {
    const newValue = {};
    newValue[key] = target;
    farmContext.setter({ ...farmContext.state, ...newValue });
    errorFields[key] = false;
  }

  useEffect(() => {
    async function startupPage() {
      farmContext.resetState();
      // 1. GET A LIST OF THE EXISTING FARM NAMES
      const farmsResponse = await axiosInstance.get(
        process.env.REACT_APP_API_URL + "/farms?max=100",
        {
          headers: {
            token: "Bearer " + user.token,
            provider: user.provider,
            "Content-Type": "application/json",
          },
        }
      );
      setExistingFarmNames(
        farmsResponse.data.data.map((farm) => farm.name.trim().toLowerCase())
      );

      // 2. GET A LIST OF THE EXISTING PARTNERS
      const partnersResponse = await axiosInstance.get(
        process.env.REACT_APP_API_URL + "/partners?max=100",
        {
          headers: {
            token: "Bearer " + user.token,
            provider: user.provider,
            "Content-Type": "application/json",
          },
        }
      );
      setExistingPartners(partnersResponse.data);
    }

    startupPage();
  }, []);

  function handleSaveAndAddFIeld() {
    if (
      existingFarmNames.includes(farmContext.state.name.trim().toLowerCase())
    ) {
      setWarningOpen(true);
      return;
    }
    if (isInputValid()) {
      axiosInstance
        .post(
          process.env.REACT_APP_API_URL + "/farms",
          {
            name: farmContext.state.name,
            province: farmContext.state.province,
            partner: farmContext.state.partner,
            ownerId: user.email,
          },
          {
            headers: {
              token: "Bearer " + user.token,
              provider: user.provider,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          // farmContext.setter({
          //   ...farmContext.state,
          //   _id: { $oid: response.data.farmId },
          // });
          GUI.setter({ ...GUI.state, fetched: false });
          navigate(`/farm/${response.data.farmId}/addfield`);
        });
    }
  }

  function isInputValid() {
    errorFields.name = farmContext.state.name.trim() === "";
    errorFields.province = farmContext.state.province.trim() === "";

    if (errorFields.name || errorFields.partner || errorFields.province) {
      setErrorFound(true);
      return false; // return false because input is invalid
    } else {
      setErrorFound(false);
      return true;
    }
  }

  var partnerValuesArray = existingPartners.map((p) => {
    return { label: p.name, value: p.id };
  });
  partnerValuesArray.push({ label: "None", value: "" });

  return (
    <Page title={"New Farm"} headerBorderColor={"border-[#34a853]"}>
      {/* FARM NAME FIELD  */}

      <div className="w-full h-full">
        <FormTextField
          errorFound={errorFields.name}
          fieldState={farmContext.state.name}
          onChange={(t) => handleStateChange(t, "name")}
          fieldLabel={"Farm Name"}
          modalTitle={"Farm Name"}
          modalDescription={
            "A farm or farm operation is used as a way to group fields in a way that is useful to the user.If you have multiple fields you can use farms to logically group them. Use a name that is recognizable to you."
          }
        />
        <FormSelectField
          fieldState={farmContext.state.partner}
          fieldLabel={"Partner Name"}
          modalTitle={"Partner Name"}
          onChange={(e) => handleStateChange(e.target.value, "partner")}
          valuesArray={partnerValuesArray}
          modalDescription={
            "Enter the name of the partner you're associated with. This will allow them to associate your data in their project while keeping your privacy. They will only have access to aggregated data of many farmers, your data will be anonymized."
          }
        />
        <FormSelectField
          errorFound={errorFields.province}
          valuesArray={CanadianProvinces}
          fieldLabel={"Province Name"}
          fieldState={farmContext.state.province}
          helperText={"Please select your province"}
          onChange={(e) => handleStateChange(e.target.value, "province")}
          modalTitle={"Province"}
          modalDescription={
            "Please Select the province where you have the majority of your business operations."
          }
        />

        {/* CONTROLS */}
        <div className="w-full  flex flex-col min-[550px]:flex-row">
          <div className="mb-5">
            <MainButton
              text={"Save and Add Field"}
              onClick={handleSaveAndAddFIeld}
            />
          </div>
          <div className="min-[550px]:ml-4">
            <MainButton text={"Cancel"} onClick={() => navigate("/")} />
          </div>
        </div>
      </div>
      <WarningMessageModal
        title={"Farm Name Already Exists"}
        description={`The Farm name ${farmContext.state.name.trim()} already exists in your profile. Please try a new name or modify the name of the existing Farm by opening it and navigating to the editing controls.`}
        open={warningOpen}
        handleClose={setWarningOpen}
      />
    </Page>
  );
}
