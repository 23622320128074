import { v4 as uuidv4 } from "uuid";

function FARM_CREATOR() {
  return {
    id: "",
    name: "",
    ownerId: "",
    province: "",
    fields: [],
    machines: [
      // {
      //   type: "SWATHER",
      //   name: "Swather 1",
      //   HP: 800,
      //   fuelUse: null,
      //   defaultAcreHour: 2,
      // },
      // {
      //   type: "TRACTOR",
      //   name: "My Little Tractor",
      //   HP: 800,
      //   fuelUse: null,
      //   defaultAcreHour: 2,
      // },
      // {
      //   type: "COMBINE",
      //   name: "Combine 1",
      //   HP: 800,
      //   fuelUse: null,
      //   defaultAcreHour: 2,
      // },
      // {
      //   type: "SPRAYER",
      //   name: "Sprayer 1",
      //   HP: 150,
      //   fuelUse: null,
      //   defaultAcreHour: 30,
      // },
    ],
    partner: "", // Partner is equivalent to partnerId
    addField() {
      this.fields.push(FIELD_CREATOR());
    },
  };
}

function FIELD_CREATOR() {
  return {
    id: "",
    farmId: "",
    name: "",
    fieldSize: "",
    fieldAddress: "",
    surfaceForm: "",
    slopeClass: "",
    soilType: "",
    surfaceSoilTexture: "",
    tillageRegime: "",
    previousTillageRegime: "",
    regimeChangeDate: "",
    Ecodistrict: "",
    SLCpolygon: "",
    soilCode: "",
    hasBoundaries: false,
    boundariesArray: [],
    fieldBoundariesSize: 0,
    cropYears: [
      {
        _id: {
          $oid: "",
        },
        fieldId: "f",
        crop: {
          cropYear: "",
          cropThisYear: "",
          cropFrequency: "",
          yieldValue: "",
          yieldUnits: "",
          previousCrop: "",
        },
        fieldOperations: {
          cultivations: [],
          fertilizerApplications: {
            preSeed: {
              machineId: "",
              hoursUsed: "",
              date: "",
            },
            withSeed: {
              machineId: "",
              hoursUsed: "",
              date: "",
            },
            postSeed: {
              machineId: "",
              hoursUsed: "",
              date: "",
            },
          },
          fertilizerRates: {
            N: {
              preSeed: "",
              withSeed: "",
              postSeed: "",
            },
            P: {
              preSeed: "",
              withSeed: "",
              postSeed: "",
            },
            K: {
              preSeed: "",
              withSeed: "",
              postSeed: "",
            },
            S: {
              preSeed: "",
              withSeed: "",
              postSeed: "",
            },
            M: {
              preSeed: "",
              withSeed: "",
              postSeed: "",
            },
          },
          pesticidesApplications: [
            {
              machineId: "",
              hoursUsed: "",
            },
          ],
        },
        harvest: {
          swather: {
            machineId: null,
            hoursUsed: null,
          },
          combine: {
            machineId: null,
            hoursUsed: null,
            avgSpeed: null,
          },
          cropDryingType: null,
          cropDryingFuel: null,
          moisture: {
            beforeDrying: null,
            afterDrying: null,
          },
        },
      },
    ], //"CROPYEAR"
  };
}

function CULTIVATION_OPERATION_CREATOR() {
  return {
    machineId: "",
    hoursUsed: "",
  };
}
function PLANTING_OPERATION_CREATOR() {
  return {
    type: "",
    hoursUsed: "",
    machineId: "",
    appliedFertilizer: false,
  };
}

function FERTILIZER_OPERATION_CREATOR() {
  return { machineId: "", hoursUsed: "", date: "" };
}

function PESTICIDE_OPERATION_CREATOR() {
  return { machineId: "", hoursUsed: "" };
}

function FERTILIZER_RATE_CREATOR() {
  return { preSeed: "", withSeed: "", postSeed: "" };
}

function CROPYEAR_CREATOR(fieldId, crop) {
  return {
    id: "",
    fieldId: fieldId,
    analysisId: false,
    crop: {
      cropYear: "",
      cropThisYear: "",
      cropFrequency: "",
      yieldValue: "",
      yieldUnits: "",
      previousCrop: "",
    },

    fieldOperations: {
      cultivations: [
        // CULTIVATION_OPERATION_CREATOR(),
        // CULTIVATION_OPERATION_CREATOR(),
        // CULTIVATION_OPERATION_CREATOR(),
        // CULTIVATION_OPERATION_CREATOR(),
      ],
      plantingOperation: PLANTING_OPERATION_CREATOR(),
      fertilizerApplications: {
        preSeed: FERTILIZER_OPERATION_CREATOR(),
        withSeed: FERTILIZER_OPERATION_CREATOR(),
        postSeed: FERTILIZER_OPERATION_CREATOR(),
      },
      fertilizerRates: {
        N: FERTILIZER_RATE_CREATOR(),
        P: FERTILIZER_RATE_CREATOR(),
        K: FERTILIZER_RATE_CREATOR(),
        S: FERTILIZER_RATE_CREATOR(),
        M: FERTILIZER_RATE_CREATOR(),
      },
      pesticidesApplications: [
        // PESTICIDE_OPERATION_CREATOR(),
        // PESTICIDE_OPERATION_CREATOR(),
        // PESTICIDE_OPERATION_CREATOR(),
        // PESTICIDE_OPERATION_CREATOR(),
        // PESTICIDE_OPERATION_CREATOR(),
      ],
    },

    harvest: {
      swather: { machineId: "", hoursUsed: "" },
      combine: { machineId: "", hoursUsed: "", avgSpeed: "" },
      cropDryingType: "",
      cropDryingFuel: "",
      moisture: {
        beforeDrying: "",
        afterDrying: "",
      },
    },
  };
}

function FOUR_R_FERTILIZER_APPLICATION_CREATOR() {
  return {
    applicationDate: "", // a date string of the form "DD-MM-YYYY"
    applicationMethod: "", // Can be a string of the following ["Banded","Broadcast"]
    fertilizerTiming: "", // Can take one of the following string values ["Fall Previous Year", "Before Planting", "Planting", "After Planting / in-crop"]
    incorporationDepth: 0, // a float value must be >= 0
    soilState: "", // Can be one of the following ["Frozen / Snow Covered","Moist","Dry","Arid","Ideal (At Field Capacity)"]
    fertilizerMixId: "", // is a mongo_db ObjectId string that makes reference to another document like a foreign key, and it's required
  };
}

function FOUR_R_SOIL_TEST_CREATOR() {
  return {
    nutrient: "", // Can be only one of the following ["N", "P", "K", "S"],
    test: "", // Can take one of the following values: ["Nitrate-N (NO₃-N) Test", "Olsen Test (Bicarbonate Phosphorus Test)", "Ammonium Acetate Extract (Potassium Test)", "Calcium Phosphate Extractable Sulphur Test", "DTPA Micronutrient Test"]
    date: "", // Date string of the form "DD-MM-YYYY"
    result: 0, // can take a float value between [0,100]
    units: "", // Can take one of the following values ["Lbs/Acre", "ppm"]
    frequency: "", // Can take one of the following values ["Yearly", "Every 5 years","Every 4 years","Every 3 years","Every 2 years","Two out of 3 years","Two out of 5 years"]
  };
}

function FOUR_R_ASSESSMENT_CREATOR() {
  return {
    _id: "",
    cropyearId: "",
    soilTests: [FOUR_R_SOIL_TEST_CREATOR()],
    fertilizerApplications: [FOUR_R_FERTILIZER_APPLICATION_CREATOR()],
    operationsQuestionnaire: {
      N: {
        Use_A_Nutrient_Balance_Calculator: false, // can be true or false
        Consult_A_CCA_Qualified_Agronomist: false, // can be true or false
        Follow_Provincial_Recommendations: false, // can be true or false
        Use_Variable_Rate_Prescription_Maps: false, // can be true or false
      },
      P: {
        Use_A_Nutrient_Balance_Calculator: false, // can be true or false
        Consult_A_CCA_Qualified_Agronomist: false, // can be true or false
        Follow_Provincial_Recommendations: false, // can be true or false
        Use_Variable_Rate_Prescription_Maps: false, // can be true or false
      },
    },
    assessmentResults: {
      rateNitrogen: {
        result: false,
        successLogs: [],
        errorLogs: [],
      },
      ratePhos: {
        result: false,
        successLogs: [],
        errorLogs: [],
      },
      timeNitrogen: {
        result: false,
        successLogs: [],
        errorLogs: [],
      },
      timePhos: {
        result: false,
        successLogs: [],
        errorLogs: [],
      },
      placementNitrogen: {
        result: false,
        successLogs: [],
        errorLogs: [],
      },
      placementPhos: {
        result: false,
        successLogs: [],
        errorLogs: [],
      },
    },
  };
}

function FERTILIZER_INGREDIENT_CREATOR() {
  return {
    _id: { $oid: "" },
    name: "",
    N: 0.0,
    P: 0.0,
    K: 0.0,
    S: 0.0,
  };
}
// function FERTILIZER_MIX_CREATOR(propIngredients = []) {
//   const ingredients = [...propIngredients];

//   const addIngredient = (ingredientId, amountInPounds) => {
//     ingredients.push({
//       ingredientId: "ingredientId",
//       amountInPounds: "amountInPounds",
//     });
//   };

//   const removeIgredient = (idx) => {
//     ingredients.splice(idx, 1);
//   };
//   return ingredients;
// }

const EmptyReportData = [
  [
    "Unique ID",
    "Ecodistrict",
    "Province",
    "Soil Type",
    "Soil Texture",
    "Surface Form",
    "Slope",
    "Year",
    "Crop",
    "Total Nitrogen (kg/ha)",
    "Total Phosphorus (kg/ha)",
    "Total Potassium (kg/ha)",
    "Total Sulfur (kg/ha)",
    "Total Other Fertilizer (kg/ha)",
    "Land Use Efficiency (ha/tonne)",
    "Provincial Land Use Efficiency (ha/tonne)",
    "Land Use Efficiency Index",
    "Energy Use GJ/tonne",
    "Provincial Energy Use (GJ/tonne)",
    "Energy Use Index",
    "GHG Emissions (tCO2/tonne)",
    "Provincial GHG Emissions (tCO2/tonne)",
    "Climate Impact Index",
    "Soil Erosion Risk Mg/ha/yr",
    "Provincial Soil Erosion Risk Mg/ha/yr",
    "Soil Erosion Index",
    "Provincial Soil Loss",
    "Provincial Yield (t/ha)",
    "Provincial Land Use",
    "Provincial Energy Use GJ/tonne crop",
    "Provincial Climate Impact",
    "Provincial Fieldwork Climate Impact (tCO2E/tcrop)",
    "Provincial NonFieldwork Climate Impact (tCO2E/tcrop)",
    "Provincial N-Fertilizer (tCO2e/tcrop)",
    "Provincial Crop Residue (tCO2e/tcrop)",
    "Provincial Leaching (tCO2e/tcrop)",
    "Provincial Volatilization (tCO2e/tcrop)",
    "Total GHG emissions (tCO2e/tcrop)",
    "Column1",
  ],
  [
    "",
    0,
    "",
    "",
    "",
    "",
    "",
    0,
    "",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    "",
  ],
];

export {
  FARM_CREATOR,
  FIELD_CREATOR,
  CROPYEAR_CREATOR,
  FERTILIZER_OPERATION_CREATOR,
  EmptyReportData,
  FOUR_R_FERTILIZER_APPLICATION_CREATOR,
  FOUR_R_SOIL_TEST_CREATOR,
  FOUR_R_ASSESSMENT_CREATOR,
  // FERTILIZER_MIX_CREATOR,
};
