import { useAuth } from "../Components/Auth/useAuth";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../Components/Header";
import TextField from "@mui/material/TextField";

import "@fontsource/roboto-slab";
import { axiosInstance } from "../Components/axiosFetchers";
import MainButton from "../Components/Buttons/MainButton";
import qs from "qs";
import Footer from "../Components/Footer";
import GrayButton from "../Components/Buttons/GrayButton";
import WarningMessageModal from "../Components/WarningMessageModal.jsx";
import React, { useState } from "react";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";

export const RegistrationPage = () => {
  document.title = "Registration Page - Field To Market Canada";

  return (
    <div className="w-screen relative text-lg  text-[#666] h-screen overflow-scroll">
      <div className="w-full mb-20">
        <Header />
      </div>

      <div className="w-full overflow-scroll">
        <div className="max-w-[706px] mx-auto px-4 pb-20">
          <h1
            style={{
              fontFamily: "Roboto Slab",
              fontSize: "30px",
              fontWeight: "700",
              marginBottom: "20px",
              color: "black",
            }}
          >
            Welcome!
          </h1>

          <p className="mb-6">
            The Fieldprint
            <sup>
              <span>&#174;</span>{" "}
            </sup>
            Platform is a pioneering assessment framework that empowers brands,
            retailers, suppliers and farmers at every stage in their
            sustainability journey, to measure the environmental impacts of
            commodity crop production and identify opportunities for continuous
            improvement.
          </p>

          <div className="p-5 bg-[#F5F5F5]">
            <SignUp />
          </div>
        </div>
      </div>
      <div className="w-full fixed bottom-0">
        <Footer />
      </div>
    </div>
  );
};

function SignUp() {
  const classes = useStyles();
  const { login } = useAuth();
  // State for form fields and errors
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    isAgregator: false,
  });

  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    password: "",
    email: "",
  });

  const navigate = useNavigate();
  const [warningOpen, setWarningOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Password validation function
  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    return passwordRegex.test(password);
  };
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "email") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      console.log(e.target.validity.valid);
    } else if (name !== "isAgregator") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      console.log(name, e.target.checked);
      setFormData((prevData) => ({
        ...prevData,
        isAgregator: e.target.checked,
      }));
    }

    // Password validation logic
    if (name === "password") {
      if (!validatePassword(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password:
            "Password must be at least 8 characters long and include uppercase, lowercase, number, and a symbol.",
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
      }
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if password is valid before submitting
    if (!validatePassword(formData.password)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password:
          "Password must be at least 8 characters long and include uppercase, lowercase, number, and a symbol.",
      }));
      return;
    }

    // Verify Email
    if (!validateEmail(formData.email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email:
          "Email is not valid, please follow the format 'email@provider.extension'",
      }));
      return;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }

    // Verify First and Last Names
    if (formData.first_name === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        first_name: "First Name Can't Be empty",
      }));
      return;
    }
    if (formData.last_name === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        last_name: "Last Name Can't be empty",
      }));
      return;
    }

    try {
      const options = {
        method: "POST",
        // headers: { "content-type": "application/x-www-form-urlencoded" },
        data: { ...formData, username: formData.email, provider: "generic" },
        url: `${process.env.REACT_APP_API_URL}/signup`,
      };

      const response = await axiosInstance(options);

      navigate("/login");
      alert("Thanks for Signing Up! Now use your credentials to sign in.");
    } catch (error) {
      const errorMessage =
        error.response?.data?.detail || "An unknown error occurred";

      setErrorMessage(
        errorMessage + " - Please contact support support@fieldtomarket.ca"
      );
      setWarningOpen(true);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar
          style={{ backgroundColor: "#F15D22" }}
          className={classes.avatar}
        >
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="first_name"
                variant="outlined"
                required
                fullWidth
                id="first_name"
                label="First Name"
                autoFocus
                value={formData["first_name"]}
                helperText={errors.first_name}
                error={!!errors.first_name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="last_name"
                label="Last Name"
                name="last_name"
                autoComplete="lname"
                value={formData["last_name"]}
                onChange={handleChange}
                helperText={errors.last_name}
                error={!!errors.last_name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                type="email"
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={formData.email}
                error={!!errors.email}
                onChange={handleChange}
                helperText={errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                value={formData.password}
                onChange={handleChange}
                error={!!errors.password}
                helperText={errors.password}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={formData.isAgregator}
                    onChange={handleChange}
                    name="isAgregator"
                    color="primary"
                  />
                }
                label={
                  <p>
                    Are you an authorized{" "}
                    <b>
                      <i>Agregator</i>
                    </b>{" "}
                    for Field to Market Canada (FTMC)?
                  </p>
                }
                labelPlacement="start"
              />
            </Grid>
          </Grid>
          <Button
            style={{ marginTop: "16px" }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/login")}
                variant="body2"
              >
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Typography variant="body2" color="textSecondary" align="center">
          {"Built with love by the "}
          <Link
            target={"_blank"}
            color="inherit"
            href="https://felixreynoso.ca/"
          >
            FxR Software
          </Link>
          {" team."}
        </Typography>
      </Box>
      <WarningMessageModal
        title={"An Error Occurred"}
        description={errorMessage}
        open={warningOpen}
        handleClose={setWarningOpen}
      />
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: 1,
    backgroundColor: "#F15D22",
  },
  form: {
    width: "100%",
    marginTop: 3,
  },
  submit: {
    margin: "3px 0 2px",
  },
}));
